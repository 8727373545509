<template>
  <a-modal
    title="用户授权"
    :width="900"
    :visible="visible"
    :confirmLoading="confirmLoading"
    okText="确认"
    cancelText="取消"
    @ok="handleSubmit"
    @cancel="handleCancel">
    <a-spin :spinning="formLoading">
      <a-checkbox-group
        v-model:value="userSelected"
        @change="onChange"
      >
        <div v-for="item in userOptions">
          <a-checkbox :value="item.value">{{item.label}}（{{item.deptName}}-{{item.postName}}）</a-checkbox>
        </div>
      </a-checkbox-group>
    </a-spin>
  </a-modal>
</template>

<script>
import { adjustRoleUser, getRoleUserConfig } from '@/api/user-rights/rolemanage'
const lodash = require('lodash')

export default {
  name: 'AcUserAuthorize',
  data () {
    return {
      visible: false,
      confirmLoading: false,
      formLoading: false,
      loading: false,
      // 是否隐藏
      bool: false,
      userOptions: [],
      userSelected: []
    }
  },
  methods: {
    onChange() {
      console.log(this.userSelected, "userSelected")
    },
    /**
     *编辑
     */
    edit (record) {
      this.roleId = record.roleId
      // 单条信息
      this.handleInfo()
      this.visible = true
    },
    /**
     * 选择后的判断
     */
    handleChange (value) {
      // 如果为自定义权限
      if (value === 4) {
        if (this.customRange < 1) {
          this.handleCustomTree()
        }
        this.bool = true
      } else {
        this.bool = false
      }
    },
    /**
     * 提交
     */
    handleSubmit () {
      this.confirmLoading = true
      adjustRoleUser({roleId: this.roleId, userIds: this.userSelected}).then(res => {
        if (res.code == 200) {
          this.handleCancel()
          this.$emit('ok')
        }
      }).catch(()=> {
        this.confirmLoading = false
      })
    },
    /**
     * 获取信息
     */
    handleInfo () {
      this.formLoading = true
      getRoleUserConfig({ roleId: this.roleId }).then((res) => {
        if (res.code === 200) {
          this.userSelected = res.data['userSelectedList']
          let users = []
          if (res.data['users'] && res.data['users'].length > 0) {
            res.data['users'].forEach(item => {
              users.push({
                label: item.realName,
                value: item.userId,
                deptName: item.deptName,
                postName: item.postName
              })
            })
            users = lodash.orderBy(users, 'deptName', 'asc')
          }
          this.userOptions = users
        }
      }).catch(e => {
        console.error(e)
      }).finally(() => {
        this.formLoading = false
      })
    },
    /**
     *取消
     */
    handleCancel () {
      this.bool = false
      this.visible = false
      this.confirmLoading = false
    }
  }
}
</script>

<style lang="less" scoped>
@import "~@/assets/styles/modal";

.tree {
  background: #f5f5f557
}
</style>
